/*
 * Project: project
 * File Created: 2019-09-20
 * Author: zhoud
 * Last Modified: 2019-09-23
 * Modified By: zhoud
 * Desc:全局ajax请求
 */
import axios from 'axios'
import { message } from 'antd'
import { HashRouter } from 'react-router-dom'
import qs from 'qs'

// axios封装
export function Axios(config, showMessage = false, stringify = false) {
  // 线上使用相对地址打包
  // const baseUrl = ``
  const baseUrl = `${window.serverIp}:${window.serverPort}`
  const version = `/api/v1`
  // 基本配置，http方法，url地址
  const { method = 'GET', url = '', params = {}, qparams = {} } = config
  let queryString = ''
  Object.keys(qparams).forEach((item, index) => {
    queryString += (index === 0 ? '' : '&') + item + '=' + qparams[item]
  })
  const baseConfig = {
    method,
    url: `${baseUrl}${version}${url}${queryString === '' ? '' : '?' + queryString
      }`,
  }
  const lowerCaseMethod = method.toLowerCase()
  if (lowerCaseMethod === 'get') {
    baseConfig.params = params
  } else if (lowerCaseMethod === 'post') {
    if (stringify) {
      baseConfig.data = qs.stringify(config.params1)
    } else {
      baseConfig.data = params
    }
    if (config.responseType) {
      baseConfig.responseType = config.responseType
    }
    if (config.headers) {
      baseConfig.headers = config.headers
    }
  }
  return new Promise((resolve, reject) => {
    axios(baseConfig)
      .then((res) => {
        if (res.data.code === 0 || res.data.code === undefined) {
          // 请求成功
          if (showMessage) {
            message.success(res.data.message)
          }
          resolve(res.data)
        } else {
          message.error(res.data.message)
          reject(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}

// 携带cookie
axios.defaults.withCredentials = true
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    if (
      response.data !== null &&
      response.data.code !== null &&
      response.data.code === 408
    ) {
      if ('没有登录或登录失效' === response.data.message) {
        const router = new HashRouter()
        router.history.push('/login')
      }
    }
    return response
  },
  (error) => {
    if (error.response) {
      switch (error.response.status) {
        case 404:
          message.error('接口请求错误')
          break
        default:
          message.error('服务器错误')
      }
      // 返回接口返回的错误信息
      return Promise.reject(error.response.data)
    }
  }
)
export default {
  // 获取公钥
  getPublicKey: function (params) {
    return Axios({
      method: 'GET',
      url: '/getPublicKey',
      params,
    })
  },
  // 登录
  login: function (params) {
    return Axios({
      method: 'POST',
      url: '/login',
      params,
    })
  },
  // 注销登录
  logout: function () {
    return Axios({
      method: 'POST',
      url: '/loginout',
    })
  },
  // 获取当前用户信息
  getCurrentUser: function () {
    return Axios({
      method: 'GET',
      url: '/User/getCurrentUser'
    })
  },
  // 获取用户列表
  getUserList: function (params) {
    return Axios({
      method: 'get',
      url: '/User/List',
      params,
    })
  },
  // 添加用户
  addUser: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/User/Add',
        params,
      },
      true
    )
  },
  // 更新用户
  UpdateUser: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/User/Update',
        params,
      },
      true
    )
  },
  // 删除用户
  DeleteUser: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/User/Delete',
        params,
      },
      true
    )
  },
  // 启用用户
  EnableUser: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/User/Enable',
        params: {},
        qparams,
      },
      true,
      true
    )
  },
  // 禁用用户
  DisableUser: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/User/Disable',
        params: {},
        qparams,
      },
      true,
      true
    )
  },
  // 修改密码
  ChangePassword: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/User/ChangePassword',
        params: params,
      },
      true
    )
  },
  // 获取角色列表
  getRoleList: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/role/pageList',
      qparams,
    })
  },
  // 创建角色
  createRole: function (params) {
    return Axios({
      method: 'POST',
      url: '/role/create',
      params,
    })
  },
  // 更新角色
  updateRole: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/power/update',
        params,
      },
      true
    )
  },
  // 删除单个角色
  deleteRole: function (params) {
    return Axios({
      method: 'POST',
      url: '/role/delete',
      params,
    })
  },
  // 删除多个角色
  deleteRoles: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/role/batchDelete',
        params,
      },
      true
    )
  },
  // 根据单位id获取角色
  getRoleByUnitId: function (qparams) {
    return Axios({
      method: 'GET',
      url: '/role/getRoleByUnitId',
      params: {},
      qparams,
    })
  },
  // 获取当前用户可分配权限
  getRolePower: function (params) {
    return Axios({
      method: 'GET',
      url: '/power/details',
      params,
    })
  },
  // 更新角色权限
  updateRolePower: function (params) {
    return Axios({
      method: 'POST',
      url: '/power/update',
      params,
    })
  },
  // 获取地址列表
  getAddressList: function (params) {
    return Axios({
      method: 'POST',
      url: '/address/queryNodeTree',
      params,
    })
  },
  // 获取两层地址树
  getSecondTree: function (params) {
    return Axios({
      method: 'GET',
      url: '/address/getSecondTree',
    })
  },
  // 新增地址信息
  addAddress: function (params) {
    return Axios({
      method: 'POST',
      url: '/address/createAddress',
      params,
    })
  },
  // 更新地点
  updateAddress: function (params) {
    return Axios({
      method: 'POST',
      url: '/address/update',
      params,
    })
  },
  //删除地点
  deleteAddress: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/address/delete',
        params,
      },
      true
    )
  },
  // 根据存档单位id获取存档单位模板列表
  getTemplateListByUnitId: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/template/getByArchivingUnitId',
        params: {},
        qparams,
      },
      false,
      true
    )
  },
  // 根据存档单位id获取未绑定部门的模板列表
  getTemplateListByUnitIdExclude: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/template/GetByUnitIdExclude',
        params: {},
        qparams,
      },
      false,
      true
    )
  },
  // 替换模板
  replaceTemplateFields: function (params) {
    return Axios({
      method: 'POST',
      url: '/template/replaceTemplate',
      params,
    })
  },
  // 更新模板
  upadteTemplateFields: function (params) {
    return Axios({
      method: 'POST',
      url: '/template/update',
      params,
    })
  },
  // 根据模板id获取模板具体信息
  getTemplateDetailById: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/tfield/getAllFieldByTemplateId',
        params: {},
        qparams,
      },
      false,
      true
    )
  },
  // 绑定模板到部门
  updateTemplateDepat: function (qparams) {
    return Axios(
      {
        method: 'POST',
        url: '/template/updateTemplateDepat',
        params: {},
        qparams,
      },
      false,
      true
    )
  },
  // 创建模板
  addTemplate: function (params) {
    return Axios({
      method: 'POST',
      url: '/template/create',
      params,
    })
  },
  // 删除模板
  deleteTemplate: function (params) {
    return Axios(
      {
        method: 'POST',
        url: '/template/delete',
        params,
      },
      true,
      false
    )
  },
  // 获取存档单位列表
  getUnitList: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: '/archivingunit/pageList',
      params,
      qparams,
    })
  },
  // 新增存档单位
  addUnit: function (params) {
    return Axios({
      method: 'POST',
      url: '/archivingunit/create',
      params,
    })
  },
  // 删除存档单位
  deleteUnit: function (params) {
    return Axios({
      method: 'POST',
      url: '/archivingunit/delete',
      params,
    })
  },
  // 更新存档单位信息
  updateUnit: function (params) {
    return Axios({
      method: 'POST',
      url: '/archivingunit/update',
      params,
    })
  },
  // 获取档案架信息
  getShelfList: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: '/shelfbase/pageList',
      params,
      qparams,
    })
  },
  // 根据id获取档案架信息
  getShelfListById: function (qparams) {
    return Axios({
      method: 'GET',
      url: '/shelfbase/getByAddress',
      qparams,
    })
  },
  // 创建档案架信息
  addShelf: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfbase/create',
      params,
    })
  },
  // 批量添加档案架
  massAddShlef: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/shelfbase/createBatch',
      params: {},
      qparams,
    })
  },
  // 更新档案架信息
  updateShelf: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfbase/update',
      params,
    })
  },
  // 删除档案架信息
  deleteShelf: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfbase/delete',
      params,
    })
  },
  // 档案架单元格信息获取
  getShelfCellList: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfcell/pageList',
      params,
    })
  },
  // 批量新增单元格
  createShelfCell: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfcell/batchCreate',
      params,
    })
  },
  // 更新单元格信息
  updateShelfCell: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfcell/update',
      params,
    })
  },
  // 删除单元格信息
  deleteShelfCell: function (params) {
    return Axios({
      method: 'POST',
      url: '/shelfcell/delete',
      params,
    })
  },
  // 获取文档数据
  getDocList: function (params, qparams, setSpinning) {
    if (setSpinning)
      setSpinning(true);
    return Axios(
      {
        method: 'POST',
        url: '/archival/pageList',
        params,
        qparams,
      },
      true
    ).finally(() => {
      if (setSpinning)
        setSpinning(false);
    });
  },
  // 上传数据
  uploadExcel: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: '/archival/importExcel',
      params,
      qparams,
      headers: {
        'Content-Type': 'application/octet-stream',
      },
    })
  },
  // 导出文档数据为excel
  exportDocExcel: function (params) {
    return Axios({
      method: 'POST',
      url: '/archival/exportExcel',
      responseType: 'blob',
      params,
    })
  },
  // 删除文档数据
  deleteDocItem: function (params1, params2) {
    return Axios({
      method: 'POST',
      url: `/archival/delete?tableName=${params2}`,
      params: params1,
    })
  },
  // 更新文档数据
  updateDocItem: function (params) {
    return Axios({
      method: 'POST',
      url: `/archival/update`,
      params,
    })
  },
  // 创建调阅请求
  createBorrowRequest: function (params) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/create`,
      params: params,
    })
  },
  // 获取请求列表
  getBorrowRequest: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/pageList`,
      params,
      qparams
    })
  },
  // 获取详情
  getBorrowRequestDetail: function (qparams) {
    return Axios({
      method: 'GET',
      url: `/borrowrequest/details`,
      params: {},
      qparams,
    })
  },
  // 改变请求状态
  updateBorrowRequest: function (params) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/updateStatus`,
      params,
    })
  },
  // 调阅请求日志
  getBorrowRequestHistory: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: `/borrowrequestlog/pageList`,
      params,
      qparams,
    })
  },
  // 新增寄送地址
  addExpressAddress: function (params) {
    return Axios({
      method: 'POST',
      url: `/express/create`,
      params: params,
    })
  },
  // 获取寄送地址
  getExpressAddress: function (qparams) {
    return Axios({
      method: 'POST',
      url: `/express/GetByUnitId`,
      params: {},
      qparams,
    })
  },
  // 获取存档单位部门
  getDepartmentList: function (qparams) {
    return Axios({
      method: 'GET',
      url: `/department/getUnitDept`,
      params: {},
      qparams,
    })
  },
  // 获取文档调阅历史
  getHistoryList: function (qparams) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/getHistoryByArchBaseInfoId`,
      params: {},
      qparams,
    })
  },
  // 创建新的部门
  createDepartment: function (params) {
    return Axios({
      method: 'POST',
      url: `/department/create`,
      params,
    })
  },
  // 更新部门信息
  updateDepartment: function (params) {
    return Axios({
      method: 'POST',
      url: `/department/update`,
      params,
    })
  },
  // 删除部门
  deleteDepartments: function (params) {
    return Axios({
      method: 'POST',
      url: `/department/delete`,
      params,
    })
  },
  // 下载文件
  downloadFile: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/file/download',
      params: {},
      qparams,
    })
  },
  // 删除文件
  deleteFile: function (params) {
    return Axios({
      method: 'POST',
      url: '/file/delete',
      params
    })
  },
  // 调阅生命周期
  borrowRequestLog: function () {
    return Axios({
      method: 'POST',
      url: '/borrowrequestlog/pageList',
      params: {},
    })
  },
  // 更新请求
  updateBorrowRequestItem: function (params) {
    return Axios({
      method: 'POST',
      url: '/borrowrequest/update',
      params,
    })
  },
  // 新增物料
  createSupplies: function (params) {
    return Axios({
      method: 'POST',
      url: `/materiel/create`,
      params,
    })
  },
  // 获取物料列表
  getSuppliesList: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/materiel/pageList',
      params: {},
      qparams,
    })
  },
  // 物料出入库
  updateSuppliesList: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/materiel/UpdateNumber',
      params: {},
      qparams,
    })
  },
  // 获取物料日志
  suppliesLogs: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: '/materiel/logPageList',
      params,
      qparams,
    })
  },
  // 删除物料
  deleteMateriel: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/materiel/delete',
      qparams,
    })
  },
  // 创建入库
  createWarehous: function (params) {
    return Axios({
      method: 'POST',
      url: '/warehous/create',
      params,
    })
  },
  // 获取入库列表
  getWarehousList: function (qparams) {
    return Axios({
      method: 'POST',
      url: '/warehous/pageList',
      qparams,
    })
  },
  // 删除入库信息
  deleteWarehousItem: function (qparams) {
    return Axios({
      method: 'DELETE',
      url: '/warehous/delete',
      qparams,
    })
  },
  // 新增上架
  createWarehousUP: function (params) {
    return Axios({
      method: 'POST',
      url: '/warehousUP/create',
      params,
    })
  },
  // 上架完成
  WarehousUpDone: function (params) {
    return Axios({
      method: 'POST',
      url: '/warehous/update',
      params,
    })
  },
  // 获取上架信息
  getWarehousUPList: function (params, qparams) {
    return Axios({
      method: 'POST',
      url: '/warehousUP/pageList',
      params,
      qparams,
    })
  },
  // 新增模板字段
  createTempField: function (params) {
    return Axios({
      method: 'POST',
      url: '/tfield/create',
      params,
    })
  },
  // 删除字段
  deleteTempField: function (params) {
    return Axios({
      method: 'POST',
      url: `/tfield/delete`,
      params,
    })
  },
  // 导出请求excel
  exportReqExcel: function (qparams) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/exportDE`,
      responseType: 'blob',
      qparams,
    })
  },
  // 获取借阅信息
  getBorrowList: function (qparams) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/getBL`,
      qparams
    })
  },
  // 提交归还申请
  backSubmit: function (params) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/UpdateBL`,
      params
    })
  },
  // 提交丢失申请
  lostSubmit: function (params) {
    return Axios({
      method: 'POST',
      url: `/borrowrequest/UpdateBL`,
      params
    })
  },
  // 获取当前可以处理的调阅请求数量
  getHandleCount: function () {
    return Axios({
      method: 'GET',
      url: `/borrowrequest/getHandleCount`
    })
  }
}

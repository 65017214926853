/*
 * Project: project
 * File Created: 2019-08-28
 * Author: zhoud
 * Last Modified: 2019-08-28
 * Modified By: zhoud
 * Desc: 用户管理页面
 */
import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { Button, Table, Modal, Tag, Card } from 'antd'
import * as userActions from '../store/user/actions'
import UserFormModal from '../components/UserManage/UserFormModal'
import Fetch from '../fetch/axios'
export class UserManage extends Component {
  state = {
    // 添加修改用户模态框显示状态
    showUserFormModalState: false,
    // 添加修改用户模态框title
    userFormModalTitle: '',
    // 勾选的用户key组成的数组
    selectedKeys: [],
    // 当前操作(编辑，删除等，由表格最后的操作触发)的用户对象
    userItemObj: null,
    // 模态框提交状态
    modalLoading: false,
    // 当前页面
    currentPage: 1,
    // 总数据条目
    total: 1,
    // 每一页条目数
    pageSize: 10
  }
  // 表格结构
  columns = [
    {
      title: '账号',
      dataIndex: 'Account',
      key: 'Account',
      align: 'center'
    },
    {
      title: '用户名',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center'
    },
    {
      title: '创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      align: 'center'
    },
    {
      title: '状态',
      render: record => (
        <div>
          {record.Status === 0 ? (
            <Tag color="geekblue" style={{ margin: '0 auto' }}>正常</Tag>
          ) : (
            <Tag color="red" style={{ margin: '0 auto' }}>禁用</Tag>
          )}
        </div>
      ),
      align: 'center'
    },
    {
      title: '描述',
      dataIndex: 'Description',
      key: 'Description',
      align: 'center'
    },
    {
      title: '电话',
      dataIndex: 'Phone',
      key: 'Phone',
      align: 'center'
    },
    {
      title: '邮箱',
      dataIndex: 'Email',
      key: 'Email',
      align: 'center'
    },
    {
      title: '上次登录时间',
      dataIndex: 'LoginTime',
      key: 'LoginTime',
      align: 'center'
    },
    {
      title: '操作',
      render: record => (
        <div>
          <span style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
                onClick={() => { this.setState({ userItemObj: record }, function() { this.openEditModal(); }); }}>编辑</span>
          <span style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
                onClick={() => { this.deleteUserById(record.Id); }}>删除</span>
          <span style={{ padding: '5px', color: 'blue', cursor: 'pointer' }}
                onClick={() => {
                  Modal.confirm({
                    title: `确定${record.Status === -1 ? '启用' : '禁用'}这个用户?`,
                    okType: 'danger',
                    onOk: () => {
                      this.toggleUserStatus(record.Status, record.Id)
                    }
                  });}}>{record.Status === -1 ? '启用' : '禁用'}
          </span>
        </div>
      ),
      align: 'center'
    }
  ]
  // 表格勾选checkbox,将勾选用户的key存入state
  rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      this.setState({
        selectedKeys: selectedRows.map(item => item.Id)
      })
    }
  }

  // 打开新增用户的表单
  openAddModal = () => {
    this.setState({
      showUserFormModalState: true,
      userFormModalTitle: '新增用户'
    })
  }

  // 提交表单
  submitForm = data => {
    this.setState(
      {
        modalLoading: true
      },
      function() {
        if (this.state.userFormModalTitle === '新增用户') {
          // 新增用户删除id字段
          delete data.Id
          Fetch.addUser(data)
            .then(() => {
              this.props.getUser({
                page: this.state.currentPage,
                pageSize: this.state.pageSize
              });
              this.updateUserTotal();
              this.setState({ modalLoading: false });
              this.closeModal()
            })
            .catch(error => {
              this.setState({ modalLoading: false })
              console.log(error)
            })
        } else if (this.state.userFormModalTitle === '编辑用户') {
          console.log('data: ',data)
          Fetch.UpdateUser(data)
            .then(() => {
              this.props.getUser({
                page: this.state.currentPage,
                pageSize: this.state.pageSize
              })
              this.closeModal()
              this.setState({ modalLoading: false })
            })
            .catch(err => {
              this.setState({ modalLoading: false })
              console.log(err)
            })
        }
      }
    )
  }

  // 打开编辑用户模态框
  openEditModal = () => {
    this.setState({
      showUserFormModalState: true,
      userFormModalTitle: '编辑用户'
    })
  }

  // 关闭模态框
  closeModal = () => {
    this.setState({
      showUserFormModalState: false,
      userFormModalTitle: '',
      userItemObj: null,
      modalLoading: false
    })
  }

  //表格翻页
  changPage = (page, pageSize) => {
    console.log(page)
  }

  // 切换用户的状态
  toggleUserStatus = (status, id) => {
    if (status === 0) {
      Fetch.DisableUser({ id }).then(() => {
        this.props.getUser()
      })
    } else {
      Fetch.EnableUser({ id }).then(() => {
        this.props.getUser()
      })
    }
  }

  // 翻页的回调事件
  pageChange = (page, pageSize) => {
    this.setState(
      {
        currentPage: page,
        pageSize: pageSize
      },
      function() {
        this.props.getUser({
          page,
          pageSize
        })
      }
    )
  }

  updateUserTotal = async () => {
    const response = await Fetch.getUserList({ pageIndex: this.state.currentPage, pageSize: this.state.pageSize });
    if (response.code !== 0)
      return;
    this.setState({
      total: response.data.rowCount,
    });
  }

  componentDidMount() {
    // 获取用户信息列表
    this.props.getUser({
      page: this.state.currentPage,
      pageSize: this.state.pageSize
    });
    this.updateUserTotal();
  }

  deleteUser = () => {
    const { getUser } = this.props
    // 删除勾选的用户
    if (this.state.selectedKeys.length > 0) {
      Modal.confirm({
        title: `确定删除这${this.state.selectedKeys.length}个用户?`,
        onOk: () => {
          Fetch.DeleteUser(this.state.selectedKeys).then(() => {
            getUser();
            this.updateUserTotal();
          })
        }
      })
    }
  };

  deleteUserById = (userId) => {
    Modal.confirm({
      title: `确定删除这个用户?`,
      okType: 'danger',
      onOk: () => {
        Fetch.DeleteUser([userId])
          .then(res => {
            this.props.getUser();
            this.updateUserTotal();
          })
          .catch(err => {
            console.log(err)
          })
      }
    });
  };

  render() {
    return (
      <Fragment>
        <div className="buttons">
          <Button type="primary" onClick={this.openAddModal} style={{ margin: '5px 10px' }}>添加</Button>
          <Button type="primary" style={{ margin: '10px' }} onClick={this.deleteUser}>删除</Button>
        </div>
        <div className="table">
          <Card>
            <Table columns={this.columns} dataSource={this.props.userList.rows}
                   rowSelection={this.rowSelection} loading={this.props.loading}
                   onChange={this.props.changPage} rowKey={record => record.Id}
                   pagination={{ onChange: this.pageChange, pageSize: this.state.pageSize, total: this.state.total }} />
          </Card>
        </div>
        {this.state.showUserFormModalState ? (
          <UserFormModal show={this.state.showUserFormModalState} title={this.state.userFormModalTitle}
                         submit={data => this.submitForm(data)} close={this.closeModal}
                         data={this.state.userItemObj} confirmLoading={this.state.modalLoading} />
        ) : null}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  // 用户列表
  userList: state.user.userList,
  // 表格数据加载状态
  loading: state.user.loading
})

const mapDispatchToProps = dispatch => {
  return {
    // 获取用户信息列表
    getUser: pageinfo => {
      dispatch(userActions.getUserList(pageinfo))
    }
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserManage)

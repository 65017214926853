import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {Link} from 'react-router-dom'
import {Badge, Button, Card, Input, message, Modal, Select, Table, Upload, Icon} from 'antd'
import RequestDetailModal from '../RequestDetailModal'
import * as globalActions from '../../../store/global/actions'
import ChangeModal from '../ChangeModal'
import Fetch from '../../../fetch/axios'
import './index.css'
import RebackModal from '../RebackModal'

export class index extends Component {

  state = {
    dataSource: [],
    showDetailModal: false,
    detail: [],
    showResolveModal: false,
    showUploadComponent: false,
    resolveModalTitle: null,
    resolvePlaceholder: null,
    uploadFileList: null,
    totalFileSize: 0,
    totalFileCount: 0,
    thisRecord: null,
    inputMsg: '',
    showChangeModal: false,
    targetStatus: null,
    changeItem: {},
    unitList: [],
    currentpage: 1,
    pageSize: 20,
    total: -1,
    // 模糊检索的内容
    searchText: '',
    // 状态检索内容
    searchStatus: '',
    // 单位检索内容
    searchType: '',
    showReBackModal: false,
    showLostModal: false,
    thisBRid: ''
  }

  beforeUpload = (file, fileList) => {
    // 限制文件大小不超过50MB
    const isLt50M = file.size / 1024 / 1024 < 50;
    if (!isLt50M) {
      message.error('文件太大，单文件不能超过50MB!');
      return isLt50M;
    }
    let totalSizeM = this.state.totalFileSize;
    for (const f of fileList)
      totalSizeM += (f.size / 1024 / 1024);
    const isLt250M = totalSizeM < 250;
    if (!isLt250M) {
      message.error(`上传文件总大小不能超过250MB! ${ this.state.totalFileSize > 0 ? ` (您已上传${this.state.totalFileSize.toFixed(2)}MB)` : '' }`);
      return isLt250M;
    }
    const totalFileCount = this.state.totalFileCount + fileList.length;
    const isFileCountLimit = totalFileCount <= 20;
    if (!isFileCountLimit) {
      message.error(`文件数量过多，最多上传20个文件！${this.state.totalFileCount > 0 ? ` (您已上传${this.state.totalFileCount}个文件)` : ''}`);
      return isFileCountLimit;
    }
    this.setState({totalFileSize: totalSizeM});
    this.setState({totalFileCount: totalFileCount});
    const result = isLt50M && isLt250M && isFileCountLimit;
    this.props.setSpinning(result);
    return result;
  };

  // 上传完成回调
  onUploadChange = (info) => {
    const filterFileList = info.fileList.filter((f) => f.hasOwnProperty("status"));
    info.fileList.length = 0;
    for (const f of filterFileList)
      info.fileList.push(f);
    this.setState({
      uploadFileList: info.fileList
    });
    if (info.file.status === 'done' || info.file.status === 'error')
      this.props.setSpinning(false);
    if (info.file.response && info.file.response.code === 0) {
      message.success(info.file.response.message);
    } else if (info.file.response && info.file.response.code === 500) {
      message.error(info.file.response.message);
    } else if (info.file.response && info.file.response.error) {
      if (info.file.response.message == null)
        info.file.response.message = 'No Message.';
      message.error(`${info.file.response.status} ${info.file.response.error}, ${info.file.response.message}`)
    } else if (info.file.status === 'error') {
      message.error('网络错误, 上传失败。（提示：可能文件超过限制大小）');
    }
  }

  onRemove = (file) => {
    const totalFileCount = this.state.totalFileCount - 1;
    this.setState({totalFileCount: totalFileCount});
    let totalSizeM = this.state.totalFileSize - (file.size / 1024 / 1024);
    this.setState({totalFileSize: totalSizeM});
    return true;
  }

  columns = [
    {
      title: '',
      key: 'Id',
      render: (record) => (
        <Fragment>
          { record.Status == 0 ? <Badge style={{ cursor: 'pointer'}} status="error" onClick={() => { }}/> : null }
        </Fragment>
      ),
      align: 'center'
    },
    {
      title: '调阅请求名称',
      dataIndex: 'Name',
      key: 'Name',
      align: 'center',
    },
    {
      title: '请求创建时间',
      dataIndex: 'CreateTime',
      key: 'CreateTime',
      align: 'center',
    },
    {
      title: '调阅文档数量',
      dataIndex: 'borrowNum',
      key: 'borrowNum',
      align: 'center',
    },
    {
      title: '单位名称',
      dataIndex: 'unitName',
      key: 'unitName',
      align: 'center',
    },
    {
      title: '调阅人',
      dataIndex: 'BorrowUserId',
      key: 'BorrowUserId',
      align: 'center',
    },
    {
      title: '调阅方式',
      render: (record) => ( <div> {record.Type === 0 ? <span>电子档</span> : <span>纸质档</span>} </div> ),
      align: 'center',
    },
    {
      title: '寄送地址',
      dataIndex: 'address',
      key: 'address',
      align: 'center'
    },
    {
      title: '调阅状态',
      dataIndex: 'StatusTxt',
      render: (text, record) => ( <div style={(record.Status === 0) ? {color: '#1890ff'} : (record.Status === 1) ? {color: '#ff8c00'} : (record.Status === 4) ? ((record.LostNum !== 0) ? {color: '#fc0000'} : {color: '#07ba2e'}) : {color: '#fc0000'}}> <span>{text}</span> </div> ),
      align: 'center',
    },
    {
      title: '操作',
      render: (record) => (
        <div style={{display: 'flex'}}>
          <Link style={{flexGrow: '1', color: '#1890ff', cursor: 'pointer'}}
                to={{ pathname: '/detail', search: JSON.stringify(record.Id) }}
                target="_blank">详情</Link>
          {record.Status === 0 &&
          (this.props.userType === 0 || this.props.userType === 1) ? (
            <Fragment>
              <span style={{flexGrow: '1', color: '#07ba2e', cursor: 'pointer'}}
                    onClick={() => { this.openResolveModal(record, 1) }}>通过</span>
              <span style={{flexGrow: '1', color: '#fc0000', cursor: 'pointer'}}
                    onClick={() => { this.openResolveModal(record, -1)}}>驳回</span>
            </Fragment>
          ) : null}
          {record.Status === 1 && record.Type === 1 && (this.props.userType === 0 || this.props.userType === 1) ? (
            <Fragment>
              <span style={{flexGrow: '1', color: '#07ba2e', cursor: 'pointer'}}
                    onClick={() => { this.openReBackModal(record.Id) }}>归还</span>
              <span style={{flexGrow: '1', color: '#fc0000', cursor: 'pointer'}}
                    onClick={() => { this.openLostModal(record.Id) }}>不归还</span>
            </Fragment>
          ) : null}
        </div>
      ),
      align: 'center',
    },
  ]

  render() {
    return (
      <Fragment>
        <div style={{background: 'white', display: 'flex'}}>
          <div style={{ fontSize: '20px', fontWeight: 'bold', padding: '15px' }}>检索</div>
          <div style={{padding: '15px'}}>
            关键字:{' '}
            <Input style={{width: '150px'}} allowClear value={this.state.searchText} onChange={this.searchChange} />
          </div>
          <div style={{padding: '15px'}}>
            档案类型:{' '}
            <Select style={{width: '100px'}} allowClear value={this.state.searchType} onChange={this.typeChange}>
              <Select.Option value={0}>电子档案</Select.Option>
              <Select.Option value={1}>纸质档案</Select.Option>
            </Select>
          </div>
          <div style={{padding: '15px'}}>
            状态:{' '}
            <Select style={{width: '100px'}} allowClear value={this.state.searchStatus} onChange={this.statusChange}>
              <Select.Option value={0}>审核中</Select.Option>
              <Select.Option value={1}>借阅中</Select.Option>
              <Select.Option value={4}>已归还</Select.Option>
              <Select.Option value={-1}>已驳回</Select.Option>
              <Select.Option value={-4}>不归还</Select.Option>
            </Select>
          </div>
          <div style={{padding: '15px'}}>
            <Button type="primary" style={{margin: '0 15px'}} onClick={this.resetSearch}>
              { '重置' }
            </Button>
            <Button type="primary" style={{margin: '0 5px'}} onClick={this.submitSearch}>
              { '检索' }
            </Button>
          </div>
        </div>
        <Card style={{marginTop: '10px'}}>
          <Table columns={this.columns} dataSource={this.state.dataSource} rowKey={(item) => item.Id}
                pagination={{ pageSize: this.state.pageSize,
                              total: this.state.total,
                              current: this.state.currentpage,
                              onChange: this.pageChange}} />
        </Card>
        {!this.state.showDetailModal ? null : (
          <RequestDetailModal visible={this.state.showDetailModal} onCancel={this.closeModal} data={this.state.detail} />
        )}
        {this.state.showResolveModal ? (
          <Modal visible={this.state.showResolveModal} title={this.state.resolveModalTitle}
                 onOk={() =>
                 {
                    const uploadFileList = this.state.uploadFileList != null
                                         && Array.isArray(this.state.uploadFileList)
                                         ? this.state.uploadFileList.map((item) => {
                                           if (item && item.response && item.response.code === 0) {
                                             return item.response.data;
                                           } else {
                                             return ''
                                           }
                                         }) : null;
                    Fetch.updateBorrowRequest({ idList: [this.state.thisRecord.Id],
                                                uploadFileList: uploadFileList,
                                                status: this.state.targetStatus,
                                                reason: this.state.inputMsg })
                         .then((res) =>
                          {
                            if (res.code === 0) {
                              this.setState(
                                {
                                  showResolveModal: false,
                                  thisRecord: null,
                                  inputMsg: '',
                                },
                                function () {
                                  this.getRequests()
                                }
                              )
                              message.success('操作成功');
                            } else {
                              message.error(res.message)
                            }
                          })
                         .catch((err) => {
                              console.log(err)
                              message.error('网络错误')
                          })
                  }}
                  onCancel={() => {
                    this.setState({
                      showResolveModal: false,
                      thisRecord: null,
                      inputMsg: '',
                      targetStatus: null,
                    })
                  }}>
              <Card bordered={false}>
                <Input.TextArea style={{ marginBottom:'20px' }}
                                placeholder={this.state.resolvePlaceholder}
                                onChange={(e) => { this.setState({ inputMsg: e.target.value })}} />
                <Upload.Dragger name="file"
                                action={`${window.serverIp}:${window.serverPort}/api/v1/file/upload`}
                                listType="picture-card"
                                multiple={true}
                                disabled={!this.state.showUploadComponent}
                                directory={false}
                                withCredentials={true}
                                style={{display: this.state.showUploadComponent ? null : 'none'}}
                                beforeUpload={this.beforeUpload}
                                onRemove={this.onRemove}
                                onChange={this.onUploadChange}>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">点击或者拖拽文件到此上传</p>
                </Upload.Dragger>
            </Card>
          </Modal>
        ) : null}
        {this.state.showChangeModal ? (
          <ChangeModal
            visible={this.state.showChangeModal}
            onOk={(data) => {
              this.submitChange(data)
            }}
            onCancel={this.closeChangeRequestModal}
            data={this.state.changeItem}
          />
        ) : null}
        {
          this.state.showReBackModal ? (
            <RebackModal title="文档归还" visible={this.state.showReBackModal}
                         showReturnUserName={true} placeholder="请输入归还备注"
                         onOk={(data) => { this.submitBack(data) }}
                         onCancel={() => { this.setState({ showReBackModal: false }) }}
                         id={this.state.thisBRid} borrowStatus={4} />
          ) : null
        }
        {
          this.state.showLostModal ? (
            <RebackModal title="文档不归还" visible={this.state.showLostModal}
                         showReturnUserName={false} placeholder="请输入不归还原因"
                         onOk={(data) => { this.submitLost(data) }}
                         onCancel={() => { this.setState({ showLostModal: false }) }}
                         id={this.state.thisBRid} borrowStatus={-4} />
          ) : null
        }
      </Fragment>
    )
  }

  // 同意请求
  openResolveModal = (record, targetStatus) => {
    this.setState({
      thisRecord: record,
      showResolveModal: true,
      uploadFileList: null,
      totalFileSize: 0,
      totalFileCount: 0,
      showUploadComponent: targetStatus === 1,
      resolveModalTitle: targetStatus === 1 ? '调阅审核通过' : targetStatus === -1 ? '调阅审核驳回' : `未知状态[${targetStatus}]`,
      resolvePlaceholder: targetStatus === 1 ? '审核通过备注' : targetStatus === -1 ? '驳回原因' : `未知状态[${targetStatus}]`,
      targetStatus,
    })
  }

  // 关闭详情模态框
  closeModal = () => {
    this.setState({
      showDetailModal: false,
    })
  }

  // 打开详情模态框
  showDetail = (id) => {
    Fetch.getBorrowRequestDetail({
      id,
    }).then((res) => {
      if (res.success === true) {
        this.setState({
          showDetailModal: true,
          detail: res.data,
        })
      }
    })
  }

  componentDidMount() {
    this.getRequests()
    this.getUnitList()
  }

  // 获取请求
  getRequests = (param = {}, showMessage = false) => {
    Fetch.getBorrowRequest(param, {
      pageIndex: this.state.currentpage,
      pageSize: this.state.pageSize
    })
      .then((res) => {
        if (res.code === 0) {
          const dataSource = res.data.rows
          const count = res.data.rowCount
          this.setState({
            dataSource,
            total: count
          })
          if (showMessage) {
            message.success('获取请求列表成功')
          }
        } else {
          message.error('获取请求列表失败')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  // 单位列表
  getUnitList = () => {
    Fetch.getUnitList({})
      .then((res) => {
        if (res.success === true) {
          this.setState({
            unitList: res.data.rows,
          })
        } else {
          message.error('请求单位列表失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('请求单位列表失败')
      })
  }

  // 修改请求
  changeRequest = (record) => {
    this.setState({
      showChangeModal: true,
      changeItem: record,
    })
  }

  // 关闭修改请求模态框
  closeChangeRequestModal = () => {
    this.setState({
      showChangeModal: false,
    })
  }

  // 提交修改
  submitChange = (data) => {
    data['filePath'] = Array.isArray(data['filePath'])
      ? data['filePath'].map((item) => {
        if (item && item.response && item.response.code === 0) {
          return item['name']
        } else {
          return ''
        }
      })
      : undefined
    Fetch.updateBorrowRequestItem(data)
      .then((res) => {
        if (res.code === 0) {
          message.success('修改请求成功')
          this.closeChangeRequestModal()
          this.getRequests()
        } else {
          message.error('修改请求失败')
        }
      })
      .catch((err) => {
        console.log(err)
        message.error('修改请求失败')
      })
  }

  searchChange = (e) => {
    this.setState({
      searchText: e.target.value
    })
  }

  statusChange = (e) => {
    this.setState({
      searchStatus: e,
    })
  }

  typeChange = (e) => {
    this.setState({
      searchType: e,
    })
  }

  resetSearch = () =>{
    this.setState({
      searchText: '',
      searchStatus: '',
      searchType: ''
    })
    this.getRequests();
  }

  submitSearch = () => {
    this.getRequests({
      keyword: this.state.searchText === '' ? null : this.state.searchText,
      status: this.state.searchStatus === '' ? null : this.state.searchStatus,
      type: this.state.searchType === '' ? null : this.state.searchType
    }, true)
  }

  // 归还模态框
  openReBackModal = (id) => {
    this.setState({
      showReBackModal: true,
      thisBRid: id
    })
  }

  // 丢失模态框
  openLostModal = (id) => {
    this.setState({
      showLostModal: true,
      thisBRid: id
    })
  }

  // 归还文档
  submitBack = (data) => {
    const { reason, returnUserName } = data;
    if (returnUserName === '') {
      message.error('请输入归还人信息');
    } else if (reason === '') {
      message.error('请输入备注信息');
    } else {
      Fetch.backSubmit(data).then((res) => {
        if (res.code === 0) {
          message.success(res.message);
          this.setState(
            {
              showReBackModal: false,
              thisBRid: '',
            },
            function () {
              this.getRequests();
            }
          );
        } else {
          message.error(res.message);
        }
      });
    }
  }

  // 丢失文档
  submitLost = (data) => {
    const {reason} = data
    if (reason === '') {
      message.error('请输入备注信息')
    } else {
      Fetch.backSubmit(data)
        .then(res => {
          if (res.code === 0) {
            message.success('借阅档案更新不归还成功.')
            this.setState({
              showLostModal: false,
              thisBRid: ''
            }, function () {
              this.getRequests()
            })
          } else {
            message.error(res.message)
          }
        })
    }
  }

  pageChange = (page, pageSize) => {
    this.setState({
      currentpage: page,
      pageSize: pageSize
    }, () => {
      this.getRequests()
    })
  }
}

const mapStateToProps = (state) => ({
  userType: state.global.userInfo.userRoles[0].Type,
  userId: state.global.userInfo.Id,
})

const mapDispatchToProps = (dispatch) => {
  return {
    setSpinning: state => {
      dispatch(globalActions.setSpinning(state))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(index)